

















import {
    Component,
    Vue
} from "vue-property-decorator";
import Mixin from "@/js/mixins";

@Component({
    mixins: [Mixin]
})
export default class RecommendList extends Vue {
    public info = {}

    goDetail(url){
        this.$router.push(url)
    }
    init() {
        this.info = JSON.parse(this.$route.query.option as string);
        console.log(this.info)
    }
}
